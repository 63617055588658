import React, { PureComponent } from 'react';
import './stylesheets/Comparison.css';
import './stylesheets/HyundaiComparison.css';

class Comparison extends PureComponent {
    state = { 
    
     }

    render() {
        var font = {
            fontFamily: 'Montserrat',
            fontSize: '25px',
        }

        if(this.props.ClientStates.Skin){
            font = {
                fontFamily: this.props.ClientStates.Skin,
                fontSize:  parseInt(this.props.ClientStates.FontSize)+11+'px'
            }
        }
        
        if(this.props.controller == true) {
            var comparisonClass = "comparisonController"
        } else if(this.props.Controls.ComparisonShow == true){
            var comparisonClass = "comparisonBox_show" 
        } else {
            var comparisonClass = "comparisonBox_hide"
        }
        var LapNo1 = "x"
        var LapNo2 = "x"
        var LapNo3 = "x"
        var LapNo4 = "x"
        var leadDriver = {
            Position: "x",
            CompetitorName: "Lead Driver",
        }
        var chaseDriver = {
            Position: "x",
            CompetitorName: "Chase Driver",
        }

        var bgColor1 = "neutralCompBG"
        var bgColor2 = "neutralCompBG"
        var bgColor3 = "neutralCompBG"
        var bgColor4 = "neutralCompBG"

        var TimeLap1 = "xxxx"   
        var TimeLap2 = "xxxx"  
        var TimeLap3 = "xxxx"  
        var TimeLap4 = "xxxx"  
        
        var ChaseLap1 = "xxxx"
        var ChaseLap2 = "xxxx"
        var ChaseLap3 = "xxxx"
        var ChaseLap4 = "xxxx"

        if(this.props.Controls.ComparisonLeadDriver){
            var competitorIndex = this.props.Session.Competitors.findIndex(object => {
                return parseInt(object.CompetitorId) === parseInt(this.props.Controls.ComparisonLeadDriver);
            });

            var leadDriver = this.props.Session.Competitors[competitorIndex]

            if(leadDriver.LapData) {
                if (leadDriver.LapData.length > 3){
                    var chaserIndex = this.props.Session.Competitors.findIndex(object => {
                        return parseInt(object.Position) === parseInt(leadDriver.Position) + 1;
                    });

                    var chaseDriver = this.props.Session.Competitors[chaserIndex]

                    if(this.props.ClientStates.TimingSystem == 'TSL'){
                        
                    }
                    var LapNo1 = leadDriver.LapData[leadDriver.LapData.length-4].LapNo;
                    var TimeLap1 = leadDriver.LapData[leadDriver.LapData.length-4].LapTime;
                    var LapNo2 = leadDriver.LapData[leadDriver.LapData.length-3].LapNo;
                    var TimeLap2 = leadDriver.LapData[leadDriver.LapData.length-3].LapTime;
                    var LapNo3 = leadDriver.LapData[leadDriver.LapData.length-2].LapNo;
                    var TimeLap3 = leadDriver.LapData[leadDriver.LapData.length-2].LapTime;
                    var LapNo4 = leadDriver.LapData[leadDriver.LapData.length-1].LapNo;
                    var TimeLap4 = leadDriver.LapData[leadDriver.LapData.length-1].LapTime;
                    var ChaseLap1 = 0
                    var ChaseLap2 = 0
                    var ChaseLap3 = 0
                    var ChaseLap4 = 0
                    var bgColor1 = "neutralCompBG"
                    var bgColor2 = "neutralCompBG"
                    var bgColor3 = "neutralCompBG"
                    var bgColor4 = "neutralCompBG"

                    if(chaseDriver){
                        if(chaseDriver.LapData){
                            // LAP 1 ------------------------------------------------
                            var ChaseLap1 = chaseDriver.LapData[chaseDriver.LapData.length-4].LapTime || 0;
                            var ChaseDiff1 = parseInt(ChaseLap1) - parseInt(TimeLap1)
                            var prefixLap1 = ""
                            if (ChaseDiff1 > 0){
                                var bgColor1 = "NegativeRed"
                                ChaseLap1 = "+" + ChaseDiff1.toFixed(3)
                                prefixLap1 = "+"
                            } else if (ChaseDiff1 < 0){
                                var bgColor1 = "PositiveGreen"
                                ChaseDiff1 = parseInt(TimeLap1) - parseInt(ChaseLap1)
                                ChaseLap1 = ChaseDiff1.toFixed(3)
                                prefixLap1 = "-"
                            } else {
                                var bgColor1 = "neutralCompBG"
                                ChaseLap1 = ChaseDiff1.toFixed(3)
                            }

                            // LAP 2 ------------------------------------------------
                            var ChaseLap2 = chaseDriver.LapData[chaseDriver.LapData.length-3].LapTime || 0;
                            var ChaseDiff2 = parseInt(ChaseLap2) - parseInt(TimeLap2)
                            var prefixLap2 = ""
                            if (ChaseDiff2 > 0){
                                var bgColor2 = "NegativeRed"
                                ChaseLap2 = "+" + ChaseDiff2.toFixed(3)
                                prefixLap2 = "+"
                            } else if (ChaseDiff2 < 0){
                                var bgColor2 = "PositiveGreen"
                                ChaseDiff2 = parseInt(TimeLap2) - parseInt(ChaseLap2)
                                ChaseLap2 = ChaseDiff2.toFixed(3)
                                prefixLap2 = "-"
                            } else {
                                var bgColor2 = "neutralCompBG"
                                ChaseLap2 = ChaseDiff2.toFixed(3)
                            }

                            // LAP 3 ------------------------------------------------
                            var ChaseLap3 = chaseDriver.LapData[chaseDriver.LapData.length-2].LapTime || 0;
                            var ChaseDiff3 = parseInt(ChaseLap3) - parseInt(TimeLap3)
                            var prefixLap3 = ""
                            if (ChaseDiff3 > 0){
                                var bgColor3 = "NegativeRed"
                                ChaseLap3 = "+" + ChaseDiff3.toFixed(3)
                                prefixLap3 = "+"
                            } else if (ChaseDiff3 < 0){
                                var bgColor3 = "PositiveGreen"
                                ChaseDiff3 = parseInt(TimeLap3) - parseInt(ChaseLap3)
                                ChaseLap3 = ChaseDiff3.toFixed(3)
                                prefixLap3 = "-"
                            } else {
                                var bgColor3 = "neutralCompBG"
                                ChaseLap3 = ChaseDiff3.toFixed(3)
                            }

                            // LAP 4 ------------------------------------------------
                            var ChaseLap4 = chaseDriver.LapData[chaseDriver.LapData.length-1].LapTime || 0
                            var prefixLap4 = ""
                            var ChaseDiff4 = parseInt(ChaseLap4) - parseInt(TimeLap4)
                            if (ChaseDiff4 > 0){
                                var bgColor4 = "NegativeRed"
                                ChaseLap4 = "+" + ChaseDiff4.toFixed(3)
                                var prefixLap4 = "+"
                            } else if (ChaseDiff4 < 0){
                                var bgColor4 = "PositiveGreen"
                                ChaseDiff4 = parseInt(TimeLap4) - parseInt(ChaseLap4)
                                ChaseLap4 = ChaseDiff4.toFixed(3)
                                var prefixLap4 = "-"
                            } else {
                                var bgColor4 = "neutralCompBG"
                                ChaseLap4 = ChaseDiff4.toFixed(3)
                            }
                        }

                    } else {
                        var chaseDriver = {
                            Position: "x",
                            CompetitorName: "Chase Driver",
                        }
                    }
                    
                    if(this.props.ClientStates.TimingSystem == 'AC' || this.props.ClientStates.TimingSystem == 'TSL'|| this.props.ClientStates.TimingSystem == 'TimeService' || this.props.ClientStates.TimingSystem == 'Alkamel'){
                        TimeLap1 = TimeLap1*1000
                        TimeLap2 = TimeLap2*1000
                        TimeLap3 = TimeLap3*1000
                        TimeLap4 = TimeLap4*1000
                        ChaseLap1 = ChaseLap1*1000
                        ChaseLap2 = ChaseLap2*1000
                        ChaseLap3 = ChaseLap3*1000
                        ChaseLap4 = ChaseLap4*1000
                    }
                    
                }
            }  
        } 

        if(this.props.ClientStates.Skin == 'Hyundai'){
            
            return(
                <div className={'hyundai'+comparisonClass} style={font}>
                <div className='hyundaiCompHead'>
                    <div className='hyundaiHeadLap'>LAP {LapNo1}</div>
                    <div className='hyundaiHeadLap'>LAP {LapNo2}</div>
                    <div className='hyundaiHeadLap'>LAP {LapNo3}</div>
                    <div className='hyundaiHeadLap'>LAP {LapNo4}</div>
                </div>
                <div className='hyundaiCompRow1'>
                    <div className='hyundaiFade' style={{width: '100%'}}></div>
                    <div className='hyundaiCompPos'>
                        <div className='hyundaiPosText'>{leadDriver.Position}</div>
                    </div>
                    <div className='hyundaiCompName1'>
                        <div className='hyundaiNameText'>{leadDriver.CompetitorName}</div>
                    </div>
                    <div className='hyundaineutralCompBG hyundaiCompLap'>
                        <div className='hyundaiLapText'>{this.props.msToTime(TimeLap1) || ""}</div>
                    </div>
                    <div className='hyundaineutralCompBG hyundaiCompLap'>
                        <div className='hyundaiLapText'>{this.props.msToTime(TimeLap2) || ""}</div>
                    </div>
                    <div className='hyundaineutralCompBG hyundaiCompLap'>
                        <div className='hyundaiLapText'>{this.props.msToTime(TimeLap3) || ""}</div>
                    </div>
                    <div className='hyundaineutralCompBG hyundaiCompLap'>
                        <div className='hyundaiLapText'>{this.props.msToTime(TimeLap4) || ""}</div>
                    </div>
                </div>
                <div className='hyundaiCompRow1'>
                    <div className='hyundaiFade' style={{width: '100%'}}></div>
                    <div className='StreamBlue hyundaiCompPos'>
                        <div className='hyundaiPosText'>{chaseDriver.Position}</div>
                    </div>
                    <div className='hyundaiCompName2'>
                        <div className='hyundaiNameText'>{chaseDriver.CompetitorName}</div>
                    </div>
                    <div className={'hyundaineutralCompBG' + ' hyundaiCompLap'}>
                        <div className='hyundaiLapText'>{prefixLap1  + this.props.msToTime(ChaseLap1) || ""}</div>
                    </div>
                    <div className={'hyundaineutralCompBG' + ' hyundaiCompLap'}>
                        <div className='hyundaiLapText'>{prefixLap2  + this.props.msToTime(ChaseLap2) || ""}</div>
                    </div>
                    <div className={'hyundaineutralCompBG' + ' hyundaiCompLap'}>
                        <div className='hyundaiLapText'>{prefixLap3  + this.props.msToTime(ChaseLap3) || ""}</div>
                    </div>
                    <div className={'hyundaineutralCompBG' + ' hyundaiCompLap'}>
                        <div className='hyundaiLapText'>{prefixLap4  + this.props.msToTime(ChaseLap4) || ""}</div>
                    </div>
                </div>
            </div>
            )
        } else {
            return ( 
                    <div className={comparisonClass} style={font}>
                        <div className='compHead'>
                            <div className='headLap'>LAP {LapNo1}</div>
                            <div className='headLap'>LAP {LapNo2}</div>
                            <div className='headLap'>LAP {LapNo3}</div>
                            <div className='headLap'>LAP {LapNo4}</div>
                        </div>
                        <div className='compRow1'>
                            <div className='compPos posBG'>
                                <div className='posText'>{leadDriver.Position}</div>
                            </div>
                            <div className='compName1'>
                                <div className='nameText'>{leadDriver.CompetitorName}</div>
                            </div>
                            <div className='neutralCompBG compLap'>
                                <div className='lapText'>{this.props.msToTime(TimeLap1) || ""}</div>
                            </div>
                            <div className='neutralCompBG compLap'>
                                <div className='lapText'>{this.props.msToTime(TimeLap2) || ""}</div>
                            </div>
                            <div className='neutralCompBG compLap'>
                                <div className='lapText'>{this.props.msToTime(TimeLap3) || ""}</div>
                            </div>
                            <div className='neutralCompBG compLap'>
                                <div className='lapText'>{this.props.msToTime(TimeLap4) || ""}</div>
                            </div>
                        </div>
                        <div className='compRow1'>
                            <div className='StreamBlue compPos'>
                                <div className='posText'>{chaseDriver.Position}</div>
                            </div>
                            <div className='compName2'>
                                <div className='nameText'>{chaseDriver.CompetitorName}</div>
                            </div>
                            <div className={bgColor1 + ' compLap'}>
                                <div className='lapText'>{prefixLap1  + this.props.msToTime(ChaseLap1) || ""}</div>
                            </div>
                            <div className={bgColor2 + ' compLap'}>
                                <div className='lapText'>{prefixLap2  + this.props.msToTime(ChaseLap2) || ""}</div>
                            </div>
                            <div className={bgColor3 + ' compLap'}>
                                <div className='lapText'>{prefixLap3  + this.props.msToTime(ChaseLap3) || ""}</div>
                            </div>
                            <div className={bgColor4 + ' compLap'}>
                                <div className='lapText'>{prefixLap4  + this.props.msToTime(ChaseLap4) || ""}</div>
                            </div>
                        </div>
                    </div>
            );
        }

            
        } 
        
    // }
}




 
export default Comparison;