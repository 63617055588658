import React, { PureComponent } from 'react';

class HyundaiGridHeader  extends PureComponent { 
    render() {

        const gridHeaderStyles = {
            position: 'relative',
            backgroundColor: 'rgb(9, 17, 30)',
            opacity: '0.8',
            height: '108px',
            width: '1165px',
            zIndex:1,
            marginLeft: '377.5px',
            fontFamily: 'Hyundai-Bold',
            fontSize: '30px'
        };

        const upperText = {
            position: 'relative',
            color: 'rgb(170 202 230)',
            marginLeft: '430px',
            top: '-100%',
            height: '50%', 
            lineHeight: '40px',
            textTransform: 'uppercase'   
        }

        const lowerText = {
            position: 'relative',
            marginLeft: '430px',
            top: '-100%',
            height: '50%',
            lineHeight: '30px',
            textTransform: 'uppercase'
        }

        const logo = {
            position: 'relative',
            height: '100%'
        }

        return (
            <div style={gridHeaderStyles}>
                <img style={logo} src='images/HyundaiGridLogo.png'/>
                <div style={upperText}>STARTING GRID</div>
                <div style={lowerText}>KOREA REGIONAL FINALS - HEAT 1</div>
            </div>
        );
    }
                //<div style={logoContainerStyles}>
                //    <img src={"images/sponsorLogos/"+this.props.ClientStates.ResultSponsorLogo} style={logoStyles}></img>
                //</div>
                // <div style={titleStylesUpper}>
                //     {this.props.Session.SessionName.toUpperCase()}
                // <div style={titleStylesLower}>UNOFFICIAL RESULTS</div>
                // </div>
}

export default HyundaiGridHeader ;