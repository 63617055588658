import React, { Component } from 'react';
import TowerHeader from './Graphics/towerHeader';
import Tower from './Graphics/tower';
import FastestLap from './Graphics/fastestLap';
import RaceControl from './Graphics/raceControl';
import Comparison from './Graphics/comparison';
import Results from './Graphics/Results/resultsView';
import Gb3Results from './Graphics/Results/gb3ResultsView';
import BasicResults from './Graphics/Results/basicResultsView';
import HyundaiResults from './Graphics/Results/hyundaiResultsView';
import Gb3FastestLap from './Graphics/gb3FastestLap'
import GinettaResults from './Graphics/Results/ginettaResultsView';
import GinettaFastestLap from './Graphics/ginettaFastestLap'
import LiveChampStandings from './Graphics/liveChampionship';
import OnboardView from './Graphics/onboardView';
import LTView from './Graphics/lowerThird';
import CategoryExplainer from './Graphics/categoryExplainer';
import HyundaiGrid from './Graphics/Grids/hyundaiGridsView';
import DataView from './Graphics/onboardData';

class Graphics extends Component {

    render() { 
        document.title = 'Graphics - Alpha Live Graphics';
        if (this.props.Session.Competitors === undefined) {
            return (
                <div></div>
            );

        } else {
            //Tower Show Setup
            if (this.props.Controls.TowerShow == true){
                var towerClass = "show"
            } else {
                var towerClass = "hide"
            }
            
            if(this.props.Camera == "true"){
                var onboard = <OnboardView {...this.props}/>
            } else {
                var onboard = <div></div>
            }

            //Session State Setup
            if (this.props.Session.fcy == true){
                var StateBarColour = "CautionYellow"
                var towerClass = "show"
            } else if (this.props.Session.State == "Running"){
                var StateBarColour = "PositiveGreen"
                var towerClass = "show"
            } else if (this.props.Session.State == "RedFlag"){
                var StateBarColour = "NegativeRed"
                var towerClass = "show"
            } else if (this.props.Session.State == "ChequeredFlag"){
                var StateBarColour = "Checkered"
                var towerClass = "show"
            } else if (this.props.Session.State == "Ended" || this.props.Session.State == "Complete"){
                var StateBarColour = "Checkered"
                var towerClass = "hide"
            } else if (this.props.Session.SD == "rMonitor - Laps" && this.props.timeRemaining == "0"){
                var StateBarColour = "Checkered"
                var towerClass = "show"
            } else {
                var StateBarColour = "StreamBlue"
                var towerClass = "hide"
            }

            if (this.props.Controls.ClockShow == false){
                var towerClass = "hide_clock"
            } else {
                var towerClass = "show_clock"
            }
            var skin = {
                fontFamily: 'Montserrat',
                fontSize: '14px'
            }
            if(this.props.ClientStates.Skin){
                skin = {
                    fontFamily: this.props.ClientStates.Skin,
                    fontSize: this.props.ClientStates.FontSize,
                    textTransform: 'uppercase',
                    letterSpacing: '0.5px',
                }
            }


            if (this.props.ClientStates.Skin == "Ginetta"){
                return ( 
                    <div className="App">
                        {onboard}
                        <GinettaFastestLap {...this.props}/>
                        <LTView {...this.props}/>
                        <CategoryExplainer {...this.props}/>
                        <RaceControl {...this.props}/>
                        <Comparison {...this.props}/>
                        <GinettaResults {...this.props}/>
                        <div className={towerClass}>
                        <TowerHeader {...this.props}/>
                        <div className={"RaceStateBar " + StateBarColour} ></div>
                        <Tower {...this.props}/>  
                        </div>
                    
                    </div>
                );
            } else if (this.props.ClientStates.Skin == "gb3"){
                return ( 
                    <div className="App">
                        {onboard}
                        <Gb3FastestLap {...this.props}/>
                        <LTView {...this.props}/>
                        <CategoryExplainer {...this.props}/>
                        <RaceControl {...this.props}/>
                        <Comparison {...this.props}/>
                        <Gb3Results {...this.props}/>
                        <div className={towerClass}>
                        <TowerHeader {...this.props}/>
                        <div className={"gb3TowerHead"} style={{"width": "337px"}}>
                            <img style={{marginLeft:"15px"}} src="images/gb3.png"></img>
                        </div>
                        <Tower {...this.props}/>  
                        </div>
                    
                    </div>
                );
            } else if (this.props.ClientStates.Skin == "basic"){

                //image calculator
                // var logo = "images/blank.png";
                // if(this.props.Session){
                //     var F4 = this.props.Session.SessionName.indexOf("F4")
                //     var PT = this.props.Session.SessionName.indexOf("Prototype")
                //     var euro = this.props.Session.SessionName.indexOf("Euro")
                //     var tour = this.props.Session.SessionName.indexOf("Tourenwagen")

                //     if(F4 > -1){
                //         logo = "images/F4.png"
                //     } else if(PT > -1){
                //         logo = "images/PT.png"
                //     } else if (euro > -1){
                //         logo = "images/EuroCup3.png"
                //     } else if (tour > -1){
                //         logo = "images/TourWagen.png"
                //     }
                // }

                return ( 
                    <div className="App">
                        {onboard}
                        <LTView {...this.props}/>
                        <CategoryExplainer {...this.props}/>
                        <RaceControl {...this.props}/>
                        <Comparison {...this.props}/>
                        <BasicResults {...this.props}/>
                        <div className={towerClass}>
                        <TowerHeader {...this.props}/>
                        {/* <div className={"gb3TowerHead"} style={{"width": "310px"}}>
                            <img src={logo}></img>
                        </div> */}
                        <Tower {...this.props}/>  
                        </div>
                    
                    </div>
                );
            } else if (this.props.ClientStates.Skin == "Hyundai"){

                var logo = "images/hyundai.png";

                return ( 
                    <div className="App">
                        {/* {onboard} */}
                        <LTView {...this.props}/>
                        {/* <CategoryExplainer {...this.props}/> */}
                        {/* <RaceControl {...this.props}/> */}
                        <Comparison {...this.props}/>
                        <HyundaiGrid {...this.props}/>
                        <HyundaiResults {...this.props}/>
                        
                        <div className={towerClass}>
                        <div className={"hyundaiTowerHead"} >
                            <img src={logo} style={{"width": "220px"}}></img>
                        </div>
                        <TowerHeader {...this.props}/>
                        
                        <Tower {...this.props}/>  
                        </div>
                        <DataView {...this.props}/>
                    </div>
                );
            } else if (this.props.ClientStates.TimingSystem == "Apex" || this.props.ClientStates.TimingSystem == "TSL"){
                return (
                    <div className="App" style={skin}>
                        {onboard}
                        <LiveChampStandings {...this.props}/>
                        <LTView {...this.props}/>
                        <CategoryExplainer {...this.props}/>
                        <FastestLap {...this.props}/>
                        <RaceControl {...this.props}/>
                        <Comparison {...this.props}/>
                        <Results {...this.props}/>
                        <div className={towerClass}>
                        <TowerHeader {...this.props}/>
                        <div className={"RaceStateBar " + StateBarColour} ></div>
                        <Tower {...this.props}/>
                        </div>
                    </div>
                )
                
            } else {
                return ( 
                <div className="App" style={skin}>
                    {onboard}
                    <LTView {...this.props}></LTView>
                    <CategoryExplainer {...this.props}/>
                    <FastestLap {...this.props}/>
                    <RaceControl {...this.props}/>
                    <Comparison {...this.props}/>
                    <Results {...this.props}/>
                    <div className={towerClass}>
                    <TowerHeader {...this.props}/>
                    <div className={"RaceStateBar " + StateBarColour} ></div>
                    <Tower {...this.props}/>  
                    
                    </div>
                
                </div>
            );
            }
            
            
        }        
    }
}
export default Graphics ;
